import { inject, Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

import { SnackbarComponent } from './snackbar.component';

@Injectable({ providedIn: 'root' })
export class SnackbarService {
    readonly #snackBar = inject(MatSnackBar);

    public showSnackbar(message: string) {
        const config: MatSnackBarConfig = {
            duration: 3000,
            data: { message: message, action: 'Got it!' },
        };
        this.#snackBar.openFromComponent(SnackbarComponent, config);
    }
}
