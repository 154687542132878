import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

import { DefaultSnackbarData } from '../../interfaces/snackbar.interface';

@Component({
    templateUrl: './snackbar.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [],
})
export class SnackbarComponent {
    public readonly data = inject<DefaultSnackbarData>(MAT_SNACK_BAR_DATA);
    readonly #snackBarRef = inject<MatSnackBarRef<SnackbarComponent>>(MatSnackBarRef);

    public dismiss() {
        this.#snackBarRef.dismiss();
    }
}
